@font-face {
    font-family: "PolySans";
    src: url("/assets/fonts/polysans/PolySansTrial-Neutral.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "PolySans";
    src: url("/assets/fonts/polysans/PolySansTrial-NeutralItalic.otf") format("opentype");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "PolySans";
    src: url("/assets/fonts/polysans/PolySansTrial-Median.otf") format("opentype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "PolySans";
    src: url("/assets/fonts/polysans/PolySansTrial-MedianItalic.otf") format("opentype");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "PolySans";
    src: url("/assets/fonts/polysans/PolySansTrial-Median.otf") format("opentype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "PolySans";
    src: url("/assets/fonts/polysans/PolySansTrial-MedianItalic.otf") format("opentype");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "PolySans";
    src: url("/assets/fonts/polysans/PolySansTrial-Bulky.otf") format("opentype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "PolySans";
    src: url("/assets/fonts/polysans/PolySansTrial-BulkyItalic.otf") format("opentype");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "PolySans";
    src: url("/assets/fonts/polysans/PolySansTrial-Slim.otf") format("opentype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "PolySans";
    src: url("/assets/fonts/polysans/PolySansTrial-SlimItalic.otf") format("opentype");
    font-weight: 300;
    font-style: italic;
}
