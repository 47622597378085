@import './styles/fontface.less';
@import './styles/animations.less';
@import './styles/base.less';
@tailwind base;
@tailwind components;
@tailwind utilities;

@import './styles/antd-customize.less';
@import './styles/other-lib.less';
@import '~swiper/less';
@import '~swiper/less/navigation';
@import '~swiper/less/pagination';
@import '~swiper/less/autoplay';

:root {
    --primary-color: #2c82f9;
    --primary-bg-text-color: #fff;
    --gradient-text-color: linear-gradient(93.65deg, #c2c8ff 0.69%, #b2e8fe 96.08%);
    --body-background-image: url('/assets/imgs/bg-dot.png');
}

html,
body {
    scroll-behavior: smooth;
}

* {
    letter-spacing: 0.02em;
}

*::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: 10px;
}

*::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;
}

*::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: none;
    background-color: rgba(0, 0, 0, 0.3);
}
