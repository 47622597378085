@import "../../../node_modules/ng-zorro-antd/ng-zorro-antd";
@import "./themes/brand";

.contact-us-modal {
    background: #181c20;
    padding: 35px 20px;

    .title {
        width: 70vw;
        font-size: 24px;
        font-weight: 700;
        color: #fff;
    }

    .close-icon {
        width: 44px;
        height: 44px;
        border: 2px solid @primary-color;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        color: @primary-color;
        position: absolute;
        top: 30px;
        right: 20px;
        z-index: 9;
    }

    .error-img {
        text-align: center;
        font-size: 60px;
        color: #ff0000;
        margin: 24px 0 30px;
        line-height: 1;
    }

    .content {
        text-align: center;
        line-height: 32px;
    }

    .footer-btns {
        text-align: center;
        margin: 20px 0 15px;

        button {
            width: 198px;
            height: 48px;
            background: @primary-color;
            border: 0;
            color: @black;
            font-size: 16px;
            font-weight: 800;
        }
    }
}

.ant-message-notice-content {
    border: none;
    padding: 0;
    border-radius: 8px;

    .ant-message-custom-content {
        border: 1px solid @black;
        border-radius: 8px;
        padding: 10px 16px;
    }

    .ant-message-error {
        border: 1px solid @error-color;
    }

    .ant-message-success {
        border: 1px solid @success-color;
    }

    .ant-message-info {
        border: 1px solid @info-color;
    }
}

.colorful-text {
    background: linear-gradient(93.65deg, #C2C8FF 0.69%, #B2E8FE 96.08%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.campaign-winner-tag {
    white-space: nowrap;
    padding: 4px 10px;
    font-size: 12px;
    border-radius: 15px;
    color: #b8deff;
    border: 1px solid #b8deff;
    background-color: #596075;
    white-space: nowrap;

    img {
        vertical-align: middle;
        margin-right: 4px;
        width: 14px;
        height: 14px;
        object-fit: contain;
    }
}

.campaign-status {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 10px;

    &.active .status-dot {
        background-color: #6ad9b6;
    }

    &.ended .status-dot {
        background-color: #596175;
    }

    &.upcoming .status-dot {
        background-color: #766ffa;
    }

    .status-dot {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 2px solid #3d4353;
        margin-right: 5px;
    }

    .status-text {
        font-size: 12px;
        text-transform: capitalize;
    }

    @media screen and(max-width:768px) {
        .status-dot {
            width: 10px;
            height: 10px;
        }

        .status-text {
            font-size: 12px;
            text-transform: capitalize;
        }
    }
}

.free-mint-color {
    color: @free-mint-color;
}

.whitelist-color {
    color: @whitelist-color;
}

.airdrop-color {
    color: @airdrop-color;
}

.giveaway-color {
    color: @giveaway-color;
}