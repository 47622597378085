@import 'brand';

.general-modal {
    background-color: #181c20;
    color: #fff;
    font-family: 'PolySans', sans-serif;
    letter-spacing: 0.03em;
    padding: 30px 20px;
    font-size: 18px;

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        z-index: 1;

        .close-icon {
            width: 44px;
            min-width: 44px;
            height: 44px;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 22px;
            color: @primary-color;
        }
    }

    .ok {
        width: 100%;
        background-color: #353d46;
        margin: 20px auto 0;
        border: none;
        height: 60px;
        font-size: 16px;
        font-weight: 500;
        color: @primary-color;
        font-family: 'PolySans', sans-serif;
        display: block;
    }
}

.ant-tooltip {
    max-width: 400px;

    @media screen and(max-width: 768px) {
        max-width: 350px;
    }
}

.ant-tooltip.contract-info-tooltip {
    background: #181c20;
    border-radius: 20px;
    padding: 20px;
    max-width: 375px;
    z-index: 999;
    border: 1px solid @primary-color;

    .ant-tooltip-inner {
        background: #181c20;
        padding: 0;
        box-shadow: none;
    }

    .ant-tooltip-arrow {
        bottom: -10px;

        .ant-tooltip-arrow-content {
            width: 12px;
            height: 12px;
            background: #181c20;
        }
    }
}

.ant-select-dropdown .ant-select-item-option-disabled {
    color: #9e9e9e;
}

.ant-modal-body {
    font-family: 'PolySans', sans-serif;
}

.ant-select-arrow {
    color: unset !important;
}

.ant-btn .anticon {
    vertical-align: middle;
}

.anticon {
    vertical-align: middle;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
    line-height: 24px;
}

.single-calendar-popup {
    .ant-picker-panels {
        .ant-picker-panel:first-child {
            .ant-picker-header-next-btn,
            .ant-picker-header-super-next-btn {
                visibility: visible !important;
            }
        }

        .ant-picker-panel:last-child {
            display: none;
        }
    }
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner,
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
    background-color: @primary-color;
    color: #000;
}

.ant-picker-time-panel-column
    > li.ant-picker-time-panel-cell
    .ant-picker-time-panel-cell-inner:hover
    .ant-table-thead
    th.ant-table-column-has-sorters.ant-table-cell-fix-left:hover {
    background-color: fade(@component-background, 80%);
}

::selection {
    color: #000;
}

a {
    color: @primary-color;
}

// Input
input.ant-input,
.ant-input-affix-wrapper {
    background: transparent !important;
    border: none !important;
    border-bottom: 1px solid #3d4353 !important;
    color: #000 !important;
    border-radius: 0 !important;
    height: 50px !important;

    &::placeholder {
        color: rgba(0, 0, 0, 0.3) !important;
    }

    &:focus,
    &:hover {
        border-color: @primary-color !important;
        box-shadow: none !important;
    }

    &.ng-invalid.ng-dirty.ng-touched {
        border-color: #d72828 !important;
        color: #d72828 !important;
        background-color: transparent !important;
    }

    & {
        @media screen and(max-width: 768px) {
            height: 44px !important;
            font-size: 12px !important;
        }
    }
}

.ant-input-affix-wrapper {
    padding: 0 10px;

    input.ant-input {
        border: none !important;
    }
}

.ant-input-number-focused {
    border-color: @primary-color;
    box-shadow: 0 0 0 2px fade(@primary-color, 20%);
}

.ant-input-number {
    border-radius: 20px;
    overflow: hidden;
}

// Radio
.ant-radio {
    top: 0.3em;
}

.ant-radio .ant-radio-inner {
    border-color: @primary-color;
    background-color: transparent;
    width: 20px;
    height: 20px;
    box-shadow: none !important;
}

.ant-radio-checked .ant-radio-inner {
    border-color: @primary-color;

    &::after {
        background-color: @primary-color;
    }
}

.ant-radio-checked::after {
    border-color: @primary-color;
}

.ant-tabs-tab {
    &:hover,
    &:focus,
    &:active {
        color: @primary-color;
    }

    .ant-tabs-tab-btn {
        &:hover,
        &:focus,
        &:active {
            color: @primary-color;
        }
    }
}

.ant-tabs-ink-bar {
    background: @primary-color;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: bold;
    color: @primary-color;
}

.ant-select .ant-select-selector {
    // border-color: @primary-color  !important;
    border-radius: 20px !important;
    // box-shadow: 0 0 0 2px fade(@primary-color, 20%) !important;
}

.ant-picker {
    border: 1px solid @input-border-color;
}

.ant-checkbox .ant-checkbox-inner {
    border-color: @primary-color;
    background-color: #3e4352;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: @primary-color;

    &::after {
        border-color: #000;
    }
}

.ant-message-notice-content {
    background-color: #ffffff;
}

.ant-table-filter-dropdown {
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    overflow: hidden;

    .ant-dropdown-menu-item-selected {
        background-color: #1f1f23;
    }
}

.ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-left:hover,
.ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-right:hover {
    background: rgba(0, 0, 0, 0.04);
}

.group-tags-dropdown {
    .ant-select-item-option-state {
        display: none;
    }

    .ant-select-item {
        background: none;
        padding: 0;
        font-weight: normal;

        button {
            margin: 6px 0 0 20px;
            padding: 0;
        }

        .group-option {
            padding: 6px 20px;

            label {
                vertical-align: middle;
                margin-right: 10px;

                input[type='checkbox'] {
                    width: 17px;
                    height: 17px;
                }
            }
        }

        &:hover {
            opacity: 0.8;
        }
    }
}

.csv-upload-sub-menu {
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 0 10px 10px 0;
    overflow: hidden;
    margin-top: -10px;
    margin-left: -4px;
}

.new-select-box-dropdown {
    .ant-select-item {
        background: none;
        text-align: center;

        &:first-child {
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        }
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background: none;
        font-weight: 500;
        color: @primary-color;
    }
}

.ant-menu {
    &:not(.ant-menu-inline) {
        &.global-header-submenu {
            margin-top: 10px;
            padding: 10px;
        }
    }

    &.global-header-submenu {
        background-color: #000;
        border-radius: 10px;
        min-width: min-content;
        color: rgba(255, 255, 255, 0.9);

        .ant-menu-item,
        .ant-menu-submenu {
            font-size: 16px;
            transition: border-radius 0s ease-in-out;

            &:hover,
            &:active {
                background-color: rgba(0, 0, 0, 0.06);
                border-radius: 10px;
            }
        }
    }
}

/**
    Antd Customization
*/

// Button
.ant-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.ant-btn:not(.ant-btn-text):not(.ant-btn-primary) {
    border: 1px solid @primary-color;
}

.socialscan-btn-primary,
.ant-btn-primary {
    transition: none;
    border: none;
    box-shadow: inset 0 0 0 2px #ffffff66;
    background: linear-gradient(90deg, @primary-color -4.12%, #35d0cd 104.12%);
    text-shadow: none;
}
.socialscan-btn-primary:hover,
.ant-btn-primary:hover {
    background: @primary-color;
    border: none;
    box-shadow: inset 0 0 0 2px #ffffff66;
}
.socialscan-btn-primary:focus,
.ant-btn-primary:focus {
    background: @primary-color;
    border: none;
    box-shadow: inset 0 0 0 2px #ffffff66;
}
.socialscan-btn-primary:active,
.ant-btn-primary:active {
    background: @primary-color;
    border: none;
    box-shadow: inset 0 0 0 2px #ffffff66;
    background: linear-gradient(90deg, #1493c5 -4.12%, #2aa6a6 104.12%);
}
.socialscan-btn-primary[disabled],
.socialscan-btn-primary[disabled]:hover,
.socialscan-btn-primary.disabled,
.socialscan-btn-primary.disabled:hover,
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover {
    background: @primary-color;
    border: none;
    box-shadow: inset 0 0 0 2px #ffffff66, inset 0px 4px 19.4px 0px #3cf0fc4d;
    background: linear-gradient(90deg, @primary-color -4.12%, #35d0cd 104.12%);
    position: relative;
    opacity: 0.5;
}

// Tabs
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
    border: none;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 4px;
    border-radius: 4px;
}

// Modal
.ant-modal {
    .ant-modal-content {
        @apply p-8;
        border-radius: 20px;

        .ant-modal-close {
            @apply absolute right-0 top-0;
            transform: translateY(-200%);
        }

        .ant-modal-close-x {
            @apply text-white text-opacity-90 text-base transition-colors;
            @apply w-8 h-8;
            @apply flex-center-center;
            @apply rounded-full border-2 border-white border-opacity-90;

            &:hover {
                @apply text-w3w border-w3w;
            }
        }
    }

    .ant-modal-header {
        @apply bg-transparent;
        @apply border-none;
        @apply p-0 pb-6;

        .ant-modal-title {
            @apply text-2xl font-normal text-center;
        }
    }

    .ant-modal-body {
        @apply p-0;
        max-height: 60vh;
        overflow-y: auto;
    }

    .ant-modal-footer {
        @apply flex-center-center gap-x-5 w-full;
        @apply border-none;
        @apply p-0 pt-6;

        button {
            @apply flex-1;
            max-width: 50%;
        }
    }
}

// Drawer
.ant-drawer {
    &.ant-drawer-bottom {
        .ant-drawer-content {
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
        }
    }

    .ant-drawer-content-wrapper {
        @apply max-w-full;

        .ant-drawer-content {
            .ant-drawer-wrapper-body {
                .ant-drawer-header {
                    background-color: transparent;
                    @apply border-none;
                    @apply px-5 py-4;

                    .ant-drawer-title {
                        @apply text-lg;
                    }

                    .ant-drawer-close {
                        @apply absolute;
                        @apply text-w3w;
                        @apply text-2xl;
                        right: 10px;
                        top: 10px;
                    }
                }
                .ant-drawer-body {
                    @media screen and(max-width:768px) {
                        padding: 10px 20px 20px;
                    }
                }
            }
        }
    }
}

.ant-message {
    .anticon {
        @apply top-0;
        @apply align-middle;
    }

    .ant-message-notice-content {
        @apply rounded-lg;
    }
}

.ant-tooltip {
    .ant-tooltip-inner {
        @apply rounded-xl text-sm;
    }
}

.ant-popover {
    .ant-popover-inner {
        border-radius: 10px;
    }
}

// Message
.ant-message-custom-content {
    padding: 10px 16px;
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 4px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.9);
    line-height: 1.3;

    .anticon {
        font-size: 24px;
    }

    &.ant-message-success {
        background: #56735d;
    }
    &.ant-message-warning {
        background: #7f604e;
    }
    &.ant-message-error {
        background: #8d5a55;
    }

    &.ant-message-info {
        background: rgba(255, 255, 255, 0.3);
    }
}

// Input
.customize-input.ant-input {
    @apply bg-form-bg;
    @apply h-12 rounded-xl text-base max-w-full;
}
// Select
.customize-select.ant-select {
    .ant-select-selector {
        @apply !h-12  max-w-full;
        @apply bg-form-bg;
        @apply h-12 rounded-xl text-base;
    }
}

// Avatar
.ant-avatar .anticon {
    vertical-align: 0.125em;
}
