@main-color: #2c82f9;

.color-picker {
    background-color: #000 !important;

    .saturation-lightness {
        border-bottom: 1px solid #555 !important;
    }

    .hex-text .box {
        div {
            color: #fff !important;
        }

        input {
            color: #fff;
            border-color: @main-color;
            background-color: #353d46;
        }
    }

    .type-policy {
        filter: brightness(100) !important;
    }

    .button-area button {
        border: none;
        background-color: @main-color;
        color: #000;
        font-family: 'Kanit', sans-serif;
        font-size: 14px;
        cursor: pointer;
    }
}

@media screen and (max-width: 768px) {
    .klaviyo-close-form {
        border: 1px solid @main-color !important;
    }
}

@media screen and (min-width: 769px) {
    div.kl-private-reset-css-Xuajs1.needsclick {
        max-width: 100% !important;
        align-items: center;

        .klaviyo-close-form {
            border: 1px solid @main-color;
        }

        form.klaviyo-form {
            max-width: 100% !important;

            p {
                line-height: 1.2;
            }

            [data-testid='form-row'] {
                justify-content: center;
                width: 840px !important;

                input {
                    width: 100%;
                }

                [data-testid='form-component']:nth-child(1) {
                    flex: none;
                }

                [data-testid='form-component']:nth-child(2) {
                    max-width: 200px !important;
                    flex: none;
                    margin-left: 20px;
                }
            }
        }
    }
}
