@import (multiple) '/node_modules/ng-zorro-antd/style/themes/dark';
@import './base';

@font-size-base: 16px;

// height rules
@height-base: 48px;
@height-lg: 56px;
@height-sm: 34px;

@border-radius-base: 4px;
@border-radius-sm: @border-radius-base;

// color palettes
@blue-1: mix(color(~`colorPalette('@{blue-base}', 8) `), @component-background, 15%);
@blue-2: mix(color(~`colorPalette('@{blue-base}', 7) `), @component-background, 25%);
@blue-3: mix(@blue-base, @component-background, 30%);
@blue-4: mix(@blue-base, @component-background, 45%);
@blue-5: mix(@blue-base, @component-background, 65%);
@blue-6: mix(@blue-base, @component-background, 85%);
@blue-7: mix(color(~`colorPalette('@{blue-base}', 5) `), @component-background, 90%);
@blue-8: mix(color(~`colorPalette('@{blue-base}', 4) `), @component-background, 95%);
@blue-9: mix(color(~`colorPalette('@{blue-base}', 3) `), @component-background, 97%);
@blue-10: mix(color(~`colorPalette('@{blue-base}', 2) `), @component-background, 98%);

@purple-1: mix(color(~`colorPalette('@{purple-base}', 8) `), @component-background, 15%);
@purple-2: mix(color(~`colorPalette('@{purple-base}', 7) `), @component-background, 25%);
@purple-3: mix(@purple-base, @component-background, 30%);
@purple-4: mix(@purple-base, @component-background, 45%);
@purple-5: mix(@purple-base, @component-background, 65%);
@purple-6: mix(@purple-base, @component-background, 85%);
@purple-7: mix(color(~`colorPalette('@{purple-base}', 5) `), @component-background, 90%);
@purple-8: mix(color(~`colorPalette('@{purple-base}', 4) `), @component-background, 95%);
@purple-9: mix(color(~`colorPalette('@{purple-base}', 3) `), @component-background, 97%);
@purple-10: mix(color(~`colorPalette('@{purple-base}', 2) `), @component-background, 98%);

@cyan-1: mix(color(~`colorPalette('@{cyan-base}', 8) `), @component-background, 15%);
@cyan-2: mix(color(~`colorPalette('@{cyan-base}', 7) `), @component-background, 25%);
@cyan-3: mix(@cyan-base, @component-background, 30%);
@cyan-4: mix(@cyan-base, @component-background, 45%);
@cyan-5: mix(@cyan-base, @component-background, 65%);
@cyan-6: mix(@cyan-base, @component-background, 85%);
@cyan-7: mix(color(~`colorPalette('@{cyan-base}', 5) `), @component-background, 90%);
@cyan-8: mix(color(~`colorPalette('@{cyan-base}', 4) `), @component-background, 95%);
@cyan-9: mix(color(~`colorPalette('@{cyan-base}', 3) `), @component-background, 97%);
@cyan-10: mix(color(~`colorPalette('@{cyan-base}', 2) `), @component-background, 98%);

@green-1: mix(color(~`colorPalette('@{green-base}', 8) `), @component-background, 15%);
@green-2: mix(color(~`colorPalette('@{green-base}', 7) `), @component-background, 25%);
@green-3: mix(@green-base, @component-background, 30%);
@green-4: mix(@green-base, @component-background, 45%);
@green-5: mix(@green-base, @component-background, 65%);
@green-6: mix(@green-base, @component-background, 85%);
@green-7: mix(color(~`colorPalette('@{green-base}', 5) `), @component-background, 90%);
@green-8: mix(color(~`colorPalette('@{green-base}', 4) `), @component-background, 95%);
@green-9: mix(color(~`colorPalette('@{green-base}', 3) `), @component-background, 97%);
@green-10: mix(color(~`colorPalette('@{green-base}', 2) `), @component-background, 98%);

@magenta-1: mix(color(~`colorPalette('@{magenta-base}', 8) `), @component-background, 15%);
@magenta-2: mix(color(~`colorPalette('@{magenta-base}', 7) `), @component-background, 25%);
@magenta-3: mix(@magenta-base, @component-background, 30%);
@magenta-4: mix(@magenta-base, @component-background, 45%);
@magenta-5: mix(@magenta-base, @component-background, 65%);
@magenta-6: mix(@magenta-base, @component-background, 85%);
@magenta-7: mix(color(~`colorPalette('@{magenta-base}', 5) `), @component-background, 90%);
@magenta-8: mix(color(~`colorPalette('@{magenta-base}', 4) `), @component-background, 95%);
@magenta-9: mix(color(~`colorPalette('@{magenta-base}', 3) `), @component-background, 97%);
@magenta-10: mix(color(~`colorPalette('@{magenta-base}', 2) `), @component-background, 98%);

@pink-1: mix(color(~`colorPalette('@{pink-base}', 8) `), @component-background, 15%);
@pink-2: mix(color(~`colorPalette('@{pink-base}', 7) `), @component-background, 25%);
@pink-3: mix(@pink-base, @component-background, 30%);
@pink-4: mix(@pink-base, @component-background, 45%);
@pink-5: mix(@pink-base, @component-background, 65%);
@pink-6: mix(@pink-base, @component-background, 85%);
@pink-7: mix(color(~`colorPalette('@{pink-base}', 5) `), @component-background, 90%);
@pink-8: mix(color(~`colorPalette('@{pink-base}', 4) `), @component-background, 95%);
@pink-9: mix(color(~`colorPalette('@{pink-base}', 3) `), @component-background, 97%);
@pink-10: mix(color(~`colorPalette('@{pink-base}', 2) `), @component-background, 98%);

@red-1: mix(color(~`colorPalette('@{red-base}', 8) `), @component-background, 15%);
@red-2: mix(color(~`colorPalette('@{red-base}', 7) `), @component-background, 25%);
@red-3: mix(@red-base, @component-background, 30%);
@red-4: mix(@red-base, @component-background, 45%);
@red-5: mix(@red-base, @component-background, 65%);
@red-6: mix(@red-base, @component-background, 85%);
@red-7: mix(color(~`colorPalette('@{red-base}', 5) `), @component-background, 90%);
@red-8: mix(color(~`colorPalette('@{red-base}', 4) `), @component-background, 95%);
@red-9: mix(color(~`colorPalette('@{red-base}', 3) `), @component-background, 97%);
@red-10: mix(color(~`colorPalette('@{red-base}', 2) `), @component-background, 98%);

@orange-1: mix(color(~`colorPalette('@{orange-base}', 8) `), @component-background, 15%);
@orange-2: mix(color(~`colorPalette('@{orange-base}', 7) `), @component-background, 25%);
@orange-3: mix(@orange-base, @component-background, 30%);
@orange-4: mix(@orange-base, @component-background, 45%);
@orange-5: mix(@orange-base, @component-background, 65%);
@orange-6: mix(@orange-base, @component-background, 85%);
@orange-7: mix(color(~`colorPalette('@{orange-base}', 5) `), @component-background, 90%);
@orange-8: mix(color(~`colorPalette('@{orange-base}', 4) `), @component-background, 95%);
@orange-9: mix(color(~`colorPalette('@{orange-base}', 3) `), @component-background, 97%);
@orange-10: mix(color(~`colorPalette('@{orange-base}', 2) `), @component-background, 98%);

@yellow-1: mix(color(~`colorPalette('@{yellow-base}', 8) `), @component-background, 15%);
@yellow-2: mix(color(~`colorPalette('@{yellow-base}', 7) `), @component-background, 25%);
@yellow-3: mix(@yellow-base, @component-background, 30%);
@yellow-4: mix(@yellow-base, @component-background, 45%);
@yellow-5: mix(@yellow-base, @component-background, 65%);
@yellow-6: mix(@yellow-base, @component-background, 85%);
@yellow-7: mix(color(~`colorPalette('@{yellow-base}', 5) `), @component-background, 90%);
@yellow-8: mix(color(~`colorPalette('@{yellow-base}', 4) `), @component-background, 95%);
@yellow-9: mix(color(~`colorPalette('@{yellow-base}', 3) `), @component-background, 97%);
@yellow-10: mix(color(~`colorPalette('@{yellow-base}', 2) `), @component-background, 98%);

@volcano-1: mix(color(~`colorPalette('@{volcano-base}', 8) `), @component-background, 15%);
@volcano-2: mix(color(~`colorPalette('@{volcano-base}', 7) `), @component-background, 25%);
@volcano-3: mix(@volcano-base, @component-background, 30%);
@volcano-4: mix(@volcano-base, @component-background, 45%);
@volcano-5: mix(@volcano-base, @component-background, 65%);
@volcano-6: mix(@volcano-base, @component-background, 85%);
@volcano-7: mix(color(~`colorPalette('@{volcano-base}', 5) `), @component-background, 90%);
@volcano-8: mix(color(~`colorPalette('@{volcano-base}', 4) `), @component-background, 95%);
@volcano-9: mix(color(~`colorPalette('@{volcano-base}', 3) `), @component-background, 97%);
@volcano-10: mix(color(~`colorPalette('@{volcano-base}', 2) `), @component-background, 98%);

@geekblue-1: mix(color(~`colorPalette('@{geekblue-base}', 8) `), @component-background, 15%);
@geekblue-2: mix(color(~`colorPalette('@{geekblue-base}', 7) `), @component-background, 25%);
@geekblue-3: mix(@geekblue-base, @component-background, 30%);
@geekblue-4: mix(@geekblue-base, @component-background, 45%);
@geekblue-5: mix(@geekblue-base, @component-background, 65%);
@geekblue-6: mix(@geekblue-base, @component-background, 85%);
@geekblue-7: mix(color(~`colorPalette('@{geekblue-base}', 5) `), @component-background, 90%);
@geekblue-8: mix(color(~`colorPalette('@{geekblue-base}', 4) `), @component-background, 95%);
@geekblue-9: mix(color(~`colorPalette('@{geekblue-base}', 3) `), @component-background, 97%);
@geekblue-10: mix(color(~`colorPalette('@{geekblue-base}', 2) `), @component-background, 98%);

@lime-1: mix(color(~`colorPalette('@{lime-base}', 8) `), @component-background, 15%);
@lime-2: mix(color(~`colorPalette('@{lime-base}', 7) `), @component-background, 25%);
@lime-3: mix(@lime-base, @component-background, 30%);
@lime-4: mix(@lime-base, @component-background, 45%);
@lime-5: mix(@lime-base, @component-background, 65%);
@lime-6: mix(@lime-base, @component-background, 85%);
@lime-7: mix(color(~`colorPalette('@{lime-base}', 5) `), @component-background, 90%);
@lime-8: mix(color(~`colorPalette('@{lime-base}', 4) `), @component-background, 95%);
@lime-9: mix(color(~`colorPalette('@{lime-base}', 3) `), @component-background, 97%);
@lime-10: mix(color(~`colorPalette('@{lime-base}', 2) `), @component-background, 98%);

@gold-1: mix(color(~`colorPalette('@{gold-base}', 8) `), @component-background, 15%);
@gold-2: mix(color(~`colorPalette('@{gold-base}', 7) `), @component-background, 25%);
@gold-3: mix(@gold-base, @component-background, 30%);
@gold-4: mix(@gold-base, @component-background, 45%);
@gold-5: mix(@gold-base, @component-background, 65%);
@gold-6: mix(@gold-base, @component-background, 85%);
@gold-7: mix(color(~`colorPalette('@{gold-base}', 5) `), @component-background, 90%);
@gold-8: mix(color(~`colorPalette('@{gold-base}', 4) `), @component-background, 95%);
@gold-9: mix(color(~`colorPalette('@{gold-base}', 3) `), @component-background, 97%);
@gold-10: mix(color(~`colorPalette('@{gold-base}', 2) `), @component-background, 98%);

// Color used by default to control hover and active backgrounds and for
// alert info backgrounds.
@primary-1: mix(color(~`colorPalette('@{primary-color}', 8) `), @component-background, 15%);
@primary-2: mix(color(~`colorPalette('@{primary-color}', 7) `), @component-background, 25%);
@primary-3: mix(@primary-color, @component-background, 30%);
@primary-4: mix(@primary-color, @component-background, 45%);
@primary-5: mix(@primary-color, @component-background, 65%);
@primary-6: @primary-color;
@primary-7: mix(color(~`colorPalette('@{primary-color}', 5) `), @component-background, 90%);
@primary-8: mix(color(~`colorPalette('@{primary-color}', 4) `), @component-background, 95%);
@primary-9: mix(color(~`colorPalette('@{primary-color}', 3) `), @component-background, 97%);
@primary-10: mix(color(~`colorPalette('@{primary-color}', 2) `), @component-background, 98%);

// Layer background
@popover-background: #0e0e0e;
@popover-customize-border-color: fade(@white, 10%);
@body-background: @black;
@component-background: #141414;

@text-color: fade(@white, 90%);
@text-color-secondary: fade(@white, 80%);
@text-color-inverse: @white;
@icon-color-hover: fade(@white, 75%);
@heading-color: fade(@white, 90%);

// The background colors for active and hover states for things like
// list items or table cells.
@item-active-bg: @primary-1;
@item-hover-bg: fade(@white, 8%);

// Border color
@border-color-base: #434343; // base border outline a component
@border-color-split: #303030; // split border inside a component

@background-color-light: fade(@white, 4%); // background of header and selected item
@background-color-base: fade(@white, 8%); // Default grey background color

// Disabled states
@disabled-color: fade(@white, 30%);
@disabled-bg: @background-color-base;
@disabled-color-dark: fade(@white, 30%);

// Buttons
@btn-font-weight: 600;
@btn-border-radius-base: @border-radius-base;
@btn-border-radius-sm: @border-radius-base;

// Tree
// ---
@tree-bg: transparent;

// List
// ---
@list-customize-card-bg: transparent;

// Shadow
// ---
@shadow-color: rgba(0, 0, 0, 0.45);
@shadow-color-inverse: @component-background;
@box-shadow-base: @shadow-2;
@shadow-1-up: 0 -6px 16px -8px rgba(0, 0, 0, 0.32), 0 -9px 28px 0 rgba(0, 0, 0, 0.2), 0 -12px 48px 16px rgba(0, 0, 0, 0.12);
@shadow-1-down: 0 6px 16px -8px rgba(0, 0, 0, 0.32), 0 9px 28px 0 rgba(0, 0, 0, 0.2), 0 12px 48px 16px rgba(0, 0, 0, 0.12);
@shadow-1-right: 6px 0 16px -8px rgba(0, 0, 0, 0.32), 9px 0 28px 0 rgba(0, 0, 0, 0.2), 12px 0 48px 16px rgba(0, 0, 0, 0.12);
@shadow-2: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);

// Buttons
// ---
@btn-shadow: none;
@btn-primary-shadow: none;
@btn-text-shadow: none;

@btn-default-bg: transparent;

@btn-default-ghost-color: @text-color;
@btn-default-ghost-border: fade(@white, 25%);

@btn-text-hover-bg: rgba(255, 255, 255, 0.03);

@btn-height-base: @height-base;
@btn-height-lg: @height-lg;
@btn-height-sm: @height-sm;

@btn-padding-horizontal-base: 24px;
@btn-padding-horizontal-lg: 56px;
@btn-padding-horizontal-sm: 8px;
@btn-font-size-sm: 14px;

@btn-border-radius-base: 8px;
@btn-border-radius-sm: 8px;

@btn-disable-color: #ffffff;

// Checkbox
// ---
@checkbox-check-bg: transparent;

// Descriptions
// ---
@descriptions-bg: @background-color-light;

// Divider
// ---
@divider-color: rgba(255, 255, 255, 12%);

// Modal
// ---
@modal-header-bg: @popover-background;
@modal-header-border-color-split: @border-color-split;
@modal-content-bg: @popover-background;
@modal-footer-border-color-split: @border-color-split;
@modal-mask-bg: fade(@black, 80%);
@modal-header-title-line-height: 28px;
@modal-header-title-font-size: 24px;

// Radio
// ---
@radio-solid-checked-color: @white;
@radio-dot-disabled-color: fade(@white, 20%);

// Radio buttons
// ---
@radio-disabled-button-checked-bg: fade(@white, 20%);
@radio-disabled-button-checked-color: @disabled-color;

// Layout
// ---
@layout-body-background: @body-background;
@layout-header-background: @popover-background;
@layout-trigger-background: #262626;

// Input
// ---
@input-placeholder-color: fade(@white, 30%);
@input-icon-color: fade(@white, 30%);
@input-number-handler-active-bg: @item-hover-bg;
@input-icon-hover-color: fade(@white, 85%);
@input-height-base: @height-base;
@input-height-lg: @height-lg;
@input-height-sm: @height-sm;
@input-border-color: transparent;
@input-bg: fade(@white, 6%);

// Select
// ---
@select-background: transparent;
@select-dropdown-bg: @popover-background;
@select-clear-background: @component-background;
@select-selection-item-bg: fade(@white, 8);
@select-selection-item-border-color: @border-color-split;
@select-multiple-disabled-background: @component-background;
@select-multiple-item-disabled-color: #595959;
@select-multiple-item-disabled-border-color: @popover-background;

// Cascader
// ---
@cascader-bg: transparent;
@cascader-menu-bg: @popover-background;
@cascader-menu-border-color-split: @border-color-split;

// Tooltip
// ---
// Tooltip background color
@tooltip-bg: #1e1e1e;

// Menu
// ---
// dark theme
@menu-dark-inline-submenu-bg: @component-background;
@menu-dark-bg: @popover-background;
@menu-popup-bg: @popover-background;

// Message
// ---
@message-notice-content-padding: 0;
@message-notice-content-bg: transparent;

// Notification
@notification-bg: @popover-background;

// LINK
@link-hover-color: @primary-5;
@link-active-color: @primary-7;

// Table
// --
@table-header-bg: #1d1d1d;
@table-body-sort-bg: fade(@white, 1%);
@table-row-hover-bg: #262626;
@table-header-cell-split-color: fade(@white, 8%);
@table-header-sort-bg: #262626;
@table-header-filter-active-bg: #434343;
@table-header-sort-active-bg: #303030;
@table-fixed-header-sort-active-bg: #222;
@table-filter-btns-bg: @popover-background;
@table-expanded-row-bg: @table-header-bg;
@table-filter-dropdown-bg: @popover-background;
@table-expand-icon-bg: transparent;

// Tag
// ---
@info-color-deprecated-bg: @primary-1;
@info-color-deprecated-border: @primary-3;
@success-color-deprecated-bg: @green-1;
@success-color-deprecated-border: @green-3;
@warning-color-deprecated-bg: @orange-1;
@warning-color-deprecated-border: @orange-3;
@error-color-deprecated-bg: @red-1;
@error-color-deprecated-border: @red-3;

// TimePicker
// ---
@picker-basic-cell-hover-with-range-color: darken(@primary-color, 35%);
@picker-basic-cell-disabled-bg: #303030;
@picker-border-color: @border-color-split;
@picker-bg: transparent;
@picker-date-hover-range-border-color: darken(@primary-color, 20%);

// Dropdown
// ---
@dropdown-menu-bg: @popover-background;
@dropdown-menu-submenu-disabled-bg: transparent;

// Steps
// ---
@steps-nav-arrow-color: fade(@white, 20%);
@steps-background: transparent;

// Avatar
// ---
@avatar-bg: fade(@white, 30%);

// Progress
// ---
@progress-steps-item-bg: fade(@white, 8%);

// Calendar
// ---
@calendar-bg: @popover-background;
@calendar-input-bg: @calendar-bg;
@calendar-border-color: transparent;
@calendar-full-bg: @component-background;

// Badge
// ---
@badge-text-color: @white;

// Popover
@popover-bg: @popover-background;

// Drawer
@drawer-bg: @popover-background;

// Card
// ---
@card-actions-background: @component-background;
@card-skeleton-bg: #303030;
@card-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.64), 0 3px 6px 0 rgba(0, 0, 0, 0.48), 0 5px 12px 4px rgba(0, 0, 0, 0.36);

// Transfer
// ---
@transfer-item-hover-bg: #262626;

// Comment
// ---
@comment-bg: transparent;
@comment-author-time-color: fade(@white, 30%);
@comment-action-hover-color: fade(@white, 65%);

// Rate
// ---
@rate-star-bg: fade(@white, 12%);

// Switch
// ---
@switch-bg: @white;

// Pagination
// ---
@pagination-item-bg: transparent;
@pagination-item-bg-active: transparent;
@pagination-item-link-bg: transparent;
@pagination-item-disabled-bg-active: fade(@white, 25%);
@pagination-item-disabled-color-active: @black;
@pagination-item-input-bg: @pagination-item-bg;

// PageHeader
// ---
@page-header-back-color: @icon-color;
@page-header-ghost-bg: transparent;

// Slider
// ---
@slider-rail-background-color: #262626;
@slider-rail-background-color-hover: @border-color-base;
@slider-dot-border-color: @border-color-split;
@slider-dot-border-color-active: @primary-4;

// Skeleton
// ---
@skeleton-to-color: fade(@white, 16%);

// Alert
// ---
@alert-success-border-color: @green-3;
@alert-success-bg-color: @green-1;
@alert-success-icon-color: @success-color;
@alert-info-border-color: @primary-3;
@alert-info-bg-color: @primary-1;
@alert-info-icon-color: @info-color;
@alert-warning-border-color: @gold-3;
@alert-warning-bg-color: @gold-1;
@alert-warning-icon-color: @warning-color;
@alert-error-border-color: @red-3;
@alert-error-bg-color: @red-1;
@alert-error-icon-color: @error-color;

// Timeline
// ---
@timeline-color: @border-color-split;
@timeline-dot-color: @primary-color;

// Mentions
// ---
@mentions-dropdown-bg: @popover-background;

// Segmented
// ---
@segmented-bg: fade(@black, 25%);
@segmented-hover-bg: fade(@black, 45%);
@segmented-selected-bg: #333333;
@segmented-label-color: fade(@white, 65%);
@segmented-label-hover-color: fade(@white, 85%);

// 自定义颜色
@free-mint-color: #6ad9b6;
@whitelist-color: #fff;
@airdrop-color: #2c82f9;
@giveaway-color: #766ffa;

.colorful-text {
    background: linear-gradient(93.65deg, #c2c8ff 0.69%, #b2e8fe 96.08%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}
