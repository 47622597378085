@media screen and (max-width: 768px) {
    .desktop-show {
        display: none !important;
    }
}

@media screen and (min-width: 769px) {
    .mobile-show {
        display: none !important;
    }
}

.page-width {
    max-width: 1450px;
    margin: 0 auto;
}

@media screen and (min-width: 1920px) {
    .page-width {
        max-width: 82vw;
        margin: 0 auto;
    }
}

.no-padding {
    .ant-popover-inner-content {
        padding: 0;
    }

    .ant-popover-arrow {
        border-color: transparent !important;
    }

    // Modal
    .ant-modal-body {
        padding: 0;
    }

    // Drawer
    .ant-drawer-body {
        padding: 0;
    }
}

.select-item-no-padding {
    .ant-select-item {
        padding: 0;
    }
}

.no-bg {
    .ant-popover-inner {
        background-color: transparent;
        box-shadow: none;
    }

    // Modal
    .ant-modal-content {
        background-color: transparent;
    }
}

.no-hover-bg:hover {
    background-color: inherit !important;
}

.dark-arrow {
    .ant-popover-arrow {
        background-color: #554764;
    }
}

.transparent-arrow {
    .ant-popover-arrow {
        background-color: #fff;
        opacity: 0;
    }
}

.transparent-arrow {
    .ant-popover-arrow {
        background-color: transparent;
        opacity: 0;
    }
}

// Typography
.h1,
.h2,
.h3,
.h4,
.h5 {
    font-weight: 700;
    color: inherit;
}

.h1 {
    font-size: 48px;
    line-height: 56px;

    @media screen and(max-width: 768px) {
        font-size: 32px;
        line-height: 40px;
    }
}

.h2 {
    font-size: 36px;
    line-height: 44px;

    @media screen and(max-width: 768px) {
        font-size: 24px;
        line-height: 32px;
    }
}

.h3 {
    font-size: 24px;
    line-height: 32px;

    @media screen and(max-width: 768px) {
        font-size: 20px;
        line-height: 28px;
    }
}

.h4 {
    font-size: 20px;
    line-height: 28px;

    @media screen and(max-width: 768px) {
        font-size: 16px;
        line-height: 24px;
    }
}

.h5 {
    font-size: 16px;
    line-height: 24px;

    @media screen and(max-width: 768px) {
        font-size: 14px;
        line-height: 22px;
    }
}

// margin
.mr-30 {
    margin-right: 30px;
}

.ml-30 {
    margin-left: 30px;
}

.mt-30 {
    margin-top: 30px;
}

.mb-30 {
    margin-bottom: 30px;
}

.m-30 {
    margin: 30px;
}

.mr-20 {
    margin-right: 20px;
}

.ml-20 {
    margin-left: 20px;
}

.mt-20 {
    margin-top: 20px;
}

.mb-20 {
    margin-bottom: 20px;
}

.m-20 {
    margin: 20px;
}

.mr-10 {
    margin-right: 10px;
}

.ml-10 {
    margin-left: 10px;
}

.mt-10 {
    margin-top: 10px;
}

.m-10 {
    margin: 10px;
}

// padding
.pr-10 {
    padding-right: 10px;
}

.pl-10 {
    padding-left: 10px;
}

.pt-10 {
    padding-top: 10px;
}

.pr-20 {
    padding-right: 20px;
}

.pl-20 {
    padding-left: 20px;
}

.pt-20 {
    padding-top: 20px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pr-30 {
    padding-right: 30px;
}

.pl-30 {
    padding-left: 30px;
}

.pt-30 {
    padding-top: 30px;
}

.pb-30 {
    padding-bottom: 30px;
}

.p-10 {
    padding: 10px;
}

.p-20 {
    padding: 20px;
}

.p-30 {
    padding: 30px;
}

// font-size
.fs-10 {
    font-size: 10px;
}

.fs-12 {
    font-size: 12px;
}

.fs-14 {
    font-size: 14px;
}

.fs-16 {
    font-size: 16px;
}

.fs-18 {
    font-size: 18px;
}

.fs-20 {
    font-size: 20px;
}

.fs-22 {
    font-size: 22px;
}

.fs-24 {
    font-size: 24px;
}

.fs-26 {
    font-size: 26px;
}

.fs-28 {
    font-size: 28px;
}

// font-weight
.fw-300 {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.fw-bold {
    font-weight: bold;
}

.fw-normal {
    font-weight: normal;
}

// flex
.d-flex {
    display: flex;
}

.justify-content-start {
    justify-content: flex-start !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.justify-content-around {
    justify-content: space-around !important;
}

.align-items-start {
    align-items: flex-start !important;
}

.align-items-center {
    align-items: center;
}

.align-items-end {
    align-items: flex-end;
}

.align-items-stretch {
    align-items: stretch;
}

.flex-direction-column {
    flex-direction: column;
}

.flex-direction-row {
    flex-direction: row;
}

.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

.flex-3 {
    flex: 3;
}

.flex-4 {
    flex: 4;
}

.row-gap-20 {
    row-gap: 20px;
}
.col-gap-20 {
    column-gap: 20px;
}
.row-gap-10 {
    row-gap: 10px;
}
.col-gap-10 {
    column-gap: 10px;
}

.vertical-align-middle {
    vertical-align: middle;
}

.vertical-align-top {
    vertical-align: top;
}

.vertical-align-bottom {
    vertical-align: bottom;
}

.text-align-center {
    text-align: center;
}

.text-align-right {
    text-align: right;
}

.text-align-left {
    text-align: left;
}

.text-decoration-underline {
    text-decoration: underline;
}

.text-decoration-linethrough {
    text-decoration: line-through;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.sticky {
    position: sticky;
}

.fixed {
    position: fixed;
}

.red {
    color: red;
}

// border-radius
.border-radius-4 {
    border-radius: 4px;
}

.border-radius-8 {
    border-radius: 8px;
}

.border-radius-12 {
    border-radius: 12px;
}

.border-radius-20 {
    border-radius: 20px;
}

// object-fit
.object-fit-cover {
    object-fit: cover;
}

.object-fit-contain {
    object-fit: contain;
}

.width-100p {
    width: 100%;
}

.height-100p {
    height: 100%;
}

.width-height-100p {
    width: 100%;
    height: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

.link-color {
    color: #2f80ed;
}

.no-wrap {
    white-space: nowrap;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-nowrap {
    flex-wrap: nowrap;
}

.w-100p {
    width: 100%;
}

.h-100p {
    height: 100%;
}

.d-none {
    display: none;
}

.active-mask:active,
.active-mask:hover {
    opacity: 0.8;
}

.user-select-none {
    user-select: none;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-word;
}

.overflow-ellipsis-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
}
.overflow-ellipsis-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
}

.overflow-ellipsis-4 {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
}

.show-scrollbar::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
    cursor: pointer;
}

.show-scrollbar::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 5px;
    background-color: rgba(85, 71, 100, 0.08);
    border: 1px solid rgba(85, 71, 100, 0.08);
}

.hide-scrollbar::-webkit-scrollbar,
.hide-scrollbar::-webkit-scrollbar-thumb {
    display: none;
}

.hover-underline:hover {
    text-decoration: underline;
}

.link-btn {
    cursor: pointer;

    &:hover {
        text-decoration: underline;
        opacity: 0.6;
    }
}

@keyframes scrollInfiniteLeft {
    0% {
        right: 0%;
    }

    100% {
        right: 50%;
    }
}

@keyframes scrollInfiniteRight {
    0% {
        right: 0%;
    }

    100% {
        right: 50%;
    }
}

.general-modal-box {
    padding: 20px;
    background-color: #181c20;
    position: relative;

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
            font-size: 24px;
            line-height: 1.3;
            font-weight: bold;
            color: #fff;
            margin: 0;
        }
    }

    footer {
        padding-top: 20px;

        .ant-btn {
            width: 200px;
            height: 48px;
            font-weight: bold;
            font-size: 16px;
            font-family: 'kanit', sans-serif;
            border: none;
        }
    }
}

@media screen and(max-width:768px) {
    .general-modal-box {
        padding: 10px;

        header {
            h2 {
                font-size: 18px;
                line-height: 1.5;
                font-weight: bold;
            }
        }

        footer {
            padding-top: 10px;

            .ant-btn {
                width: 100%;
            }
        }
    }
}

.hover-scale-1\.05,
.card-animation {
    transition: all 0.15s linear;

    &:hover {
        transform: scale(1.05);
    }
}

@media screen and(max-width:768px) {
    .hover-scale-1\.05:hover {
        transform: scale(1);
    }
}

// hemera main button
.h-main-btn,
.h-main-btn.ant-btn.ant-btn-primary {
    background-color: #2c82f9;
    color: white;
    transition: background 0.15s ease-in-out;

    &:hover {
        background-color: #1374f8;
        color: white;
    }
}

.h-secondary-btn,
.h-secondary-btn.ant-btn {
    border: 1px solid #f5f5f5;
    background-color: transparent;
    color: #2c82f9;
    transition: background 0.25s ease-in-out;

    &:hover {
        background-color: #f5f5f5;
        color: #1374f8;
    }
}
